import {Controller} from '@hotwired/stimulus'
import $ from 'jquery'

const claims = [
    {
        'text': 'Manchmal musst Du einfach nur gegenwärtig sein und innehalten, damit das Glück Dich finden kann.',
        'author': null
    },
    {
        'text': '\'\'Der Sinn des Lebens liegt nicht darin, dass wir ihn finden, sondern dass wir ihn immer wieder suchen.\'\'',
        'author': 'Enrst Ferstl'
    },
    {
        'text': 'Gib nicht so schnell auf… manche Tür, von der Du glaubst sie sei verschlossen, klemmt nur ein wenig.',
        'author': null
    },
    {
        'text': '\'\'Wann immer es Dir möglich ist, schaue nach innen. Dann wirst Du sehen, ob Du unbewusst einen Konflikt zwischen Innen- und Außenwelt erzeugst.\'\'',
        'author': 'E. Tolle'
    },
    {
        'text': '\'\'Glücklich zu sein bedeutet nicht, dass alles perfekt ist. Es bedeutet, dass Du Dich entscheidest, das Unvollkommene für den Augenblick zu akzeptieren.\'\'',
        'author': null
    },
    {
        'text': '\'\'Das Leben schenkt uns alle Zutaten. Was wir daraus machen, liegt an uns.\'\'',
        'author': 'Alexander Soor'
    },
    {
        'text': '\'\'Glück und Zufriedenheit kannst Du nur in Dir selbst finden.\'\'',
        'author': null
    },
    {
        'text': '\'\'Jedes Ding hat drei Seiten: Eine, die ich sehe. eine, die Du siehst und eine, die wir beide nicht sehen.\'\'',
        'author': 'aus China'
    },
    {
        'text': '\'\'Das Vergleichen ist das Ende des Glücks und der Anfang der Unzufriedenheit.\'\'',
        'author': 'Sören Kierkegaard'
    },
    {
        'text': '\'\'Inneren Frieden wird nur erlangen, wer Unwesentliches unbeachtet lässt und sich allein um Wesentliches sorgt.\'\'',
        'author': 'Claireraux'
    },
    {
        'text': '\'\'Die größte Kraft des Lebens ist die Dankbarkeit.\'\'',
        'author': 'H. Bezzel'
    },
    {
        'text': 'Die Fähigkeit, das Wort \'\'NEIN\'\', auszusprechen ist der erste Schritt zur Freiheit.\'\'',
        'author': 'Chanfort'
    },
    {
        'text': '\'\'Wir lassen uns zu sehr einschränken von dem, was fehlt, anstatt zu schätzen, was wir haben.\'\'',
        'author': null
    },
    {
        'text': '\'\'Liebe bedeutet geben, ohne zu verlangen. Nehmen, ohne zu besitzen. Teilen ohne Warum. Halten, ohne zu fesseln.\'\'',
        'author': 'Mike McInnes'
    },
    {
        'text': '\'\'Der Mensch ist umso reicher, je mehr Dinge er lassen kann.\'\'',
        'author': 'Thoreau'
    }
]
const pictures = [
    {
        'file': 'klangschale_2020_01.jpg'
    },
    {
        'file': 'klangschale_2020_02.jpg'
    },
    {
        'file': 'klangschale_2020_03.jpg'
    },
    {
        'file': 'klangschale_2020_04.jpg'
    },
    {
        'file': 'klangschale_2020_05.jpg'
    },
    {
        'file': 'klangschale_2020_06.jpg'
    },
    {
        'file': 'klangschale_2020_07.jpg'
    },
    {
        'file': 'klangschale_2020_08.jpg'
    },
    {
        'file': 'klangschale_2020_09.jpg'
    },
    {
        'file': 'klangschale_2020_10.jpg'
    },
    {
        'file': 'klangschale_2020_11.jpg'
    },
    {
        'file': 'klangschale_2020_12.jpg'
    }
]
export default class extends Controller {
    static targets = ['picture', 'claim', 'author']
    static values = {
        picFolder: String
    }

    connect() {

        const switchAll = () => {
            setPicture(pictures[Math.floor(Math.random() * pictures.length)])
            setClaim(claims[Math.floor(Math.random() * claims.length)])
        }

        const setPicture = picture => {
            const element = $(this.pictureTarget)
            element.attr('src', this.picFolderValue + '/' + picture.file)
        }

        const setClaim = claim  => {
            let claimElement = $(this.claimTarget)
            let authorElement = $(this.authorTarget)
            claimElement.html(claim.text)
            if (claim.author) {
                authorElement.show()
                authorElement.html(claim.author)
            } else {
                authorElement.hide()
            }
        }


        setPicture(pictures[Math.floor(Math.random() * pictures.length)])
        setClaim(claims[Math.floor(Math.random() * claims.length)])
        setInterval(switchAll, 15000)
    }

}